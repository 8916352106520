// import { useDispatch } from "react-redux"

import { useFormik } from "formik"
import { Close } from "@mui/icons-material"

import { RadioBtn } from "shared/ui/button/RadioBtn"
import { Modal } from "shared/ui/modal/Modal"
// import { setStatus } from "pages/bookings/slice"

export function ChangeStatus({ Booking, active, setActive }) {
  // const dispatch = useDispatch()
  const { values, handleChange, handleSubmit, handleReset, setFieldValue } =
    useFormik({
      initialValues: {
        status: {
          "#EDF1F7": "queue",
          "#FFF5DD": "progress",
          "#D5F5CD": "payed",
        }[Booking.color],
      },
      onSubmit: ({ status }) => {
        // dispatch(setStatus({ Booking, status }))
        setActive(false)
      },
      onReset: () => setActive(false),
    })

  return (
    <Modal active={active} setActive={setActive} className='max-w-[340px]'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-xl font-medium text-blue'>Статус</h1>
        <button onClick={handleReset} className='text-gray hover:text-black'>
          <Close />
        </button>
      </div>
      <div
        className='[&>div]:cursor-pointer
        text-blue text-base flex flex-col items-left h-full
        [&>div]:mb-6
      '
      >
        <div className='flex items-center'>
          <RadioBtn
            name={"status"}
            value={"payed"}
            checked={values.status === "payed"}
            onChange={handleChange}
          />
          <span
            className='w-full'
            onClick={() => setFieldValue("status", "payed")}
          >
            Оплачен
          </span>
        </div>
        <div className='mr-6 flex items-center'>
          <RadioBtn
            name={"status"}
            value={"progress"}
            checked={values.status === "progress"}
            onChange={handleChange}
          />
          <span
            className='w-full'
            onClick={() => setFieldValue("status", "progress")}
          >
            В процессе
          </span>
        </div>
        <div className='mr-6 flex items-center'>
          <RadioBtn
            name={"status"}
            value={"queue"}
            checked={values.status === "queue"}
            onChange={handleChange}
          />
          <span
            className='w-full'
            onClick={() => setFieldValue("status", "queue")}
          >
            В очереди
          </span>
        </div>
      </div>
      <div className='flex'>
        <button onClick={handleReset} className='btnOutlined mr-4 flex-1'>
          Отмена
        </button>
        <button
          type='submit'
          onClick={handleSubmit}
          className='btnFilled flex-1'
        >
          Сохранить
        </button>
      </div>
    </Modal>
  )
}