import { Done } from "@mui/icons-material"

export function StepsBar({ mounted, pageNum }) {
  return (
    <div
      className={`${
        mounted ? "translate-x-0" : "translate-x-[-100%]"
      } transition-transform duration-500 bg-blue sm:h-screen sm:px-7 sm:py-24 sm:w-[360px] w-[100vw]    sm:flex sm:flex-col  sm:p-0  mobile:h-[80px] mx-auto    sm:rounded-md`}
    >
      <div className='hidden sm:flex'>
        <img src='./assets/logo/logo.svg' alt='logo' />
      </div>
      <p className='text-white text-2xl font-medium sm:mt-7 mt-[6px]  text-center sm:text-left sm:ml-6'>
        Регистрация
      </p>

      <div className='sm:mt-11 flex flex-row sm:flex-col sm:w-[360px] -mt-2 mobile:justify-between   sm:gap-0   ml-2 sm:ml-[0]'>
        <div className='flex sm:w-fit '>
          <div className='flex sm:flex-col items-center gap-[5px] sm:gap-[0]'>
            <span className={`circle ${pageNum >= 1 && "bg-white "}`}>
              <span
                className={`dot ${
                  pageNum === 1 ? "bg-orange" : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 1 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line mobile:ml-[22px] sm:ml-[-6px] '>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 1 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='sm:flex sm:flex-col sm:ml-3 justify-center sm:h-fit sm:mt-1 hidden'>
            <p className={`${pageNum >= 1 ? "text-white" : "text-gray"}  `}>
              Персональные данные
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex sm:flex-col items-center gap-[5px] sm:gap-0'>
            <span className={`circle ${pageNum >= 2 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum < 2
                    ? "bg-white"
                    : pageNum === 2
                      ? "bg-orange"
                      : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 2 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line ml-[22px] sm:ml-[-6px]'>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 2 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='sm:flex  hidden flex-col ml-3 justify-center h-fit mt-1 whitespace-nowrap overflow-hidden text-ellipsis'>
            <p className={`${pageNum >= 2 ? "text-white" : "text-gray"} `}>
              Код подтверждения
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex sm:flex-col items-center gap-[5px] sm:gap-0'>
            <span
              className={`circle ${
                pageNum >= 3 ? "bg-white" : "bg-transparent"
              }`}
            >
              <span
                className={`dot ${
                  pageNum < 3
                    ? "bg-white"
                    : pageNum === 3
                      ? "bg-orange"
                      : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 3 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line ml-[22px] sm:ml-[-6px]'>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 3 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='sm:flex  hidden flex-col ml-3 justify-center h-fit mt-1'>
            <p className={`${pageNum >= 3 ? "text-white" : "text-gray"}`}>
              Введите новый пароль
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex sm:flex-col items-center gap-[5px] sm:gap-0'>
            <span className={`circle ${pageNum >= 4 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum < 4
                    ? "bg-white"
                    : pageNum === 4
                      ? "bg-orange"
                      : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 4 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line ml-[22px] sm:ml-[-6px] '>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 4 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='sm:flex  hidden flex-col ml-3 justify-center h-fit mt-1'>
            <p className={`${pageNum >= 4 ? "text-white" : "text-gray"}`}>
              Выберите тип аккаунта
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex sm:flex-col items-center  '>
            <span className={`circle ${pageNum >= 5 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum < 5
                    ? "bg-white"
                    : pageNum === 5
                      ? "bg-orange"
                      : "bg-transparent"
                } flex items-center justify-center  `}
              >
                {pageNum > 5 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
          </div>

          <div className='sm:flex  hidden flex-col ml-3 justify-center h-fit mt-1'>
            <p className={`${pageNum >= 5 ? "text-white" : "text-gray"}`}>
              Выберите сферу деятельности
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}