import { reaction } from "mobx"
import { addDisposer, flow, types } from "mobx-state-tree"
import { AuthService } from "resources"

const User = types.model("User", {
  id: types.maybe(types.number),
  images: types.maybeNull(types.array(types.string)),
  name: types.optional(types.string, ""),
  surname: types.maybeNull(types.string, ""),
  phone: types.maybe(types.string),
  salonId: types.maybeNull(types.number),
})

export const AuthStore = types
  .model("AutoStore", {
    accessToken: types.optional(types.string, ""),
    refreshToken: types.optional(types.string, ""),
    user: types.maybeNull(User),
    role: types.maybe(types.string), // @DEBUG role maybe exists in userObject
    checkingIsAuth: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    loadData: flow(function* ({ phone, password }) {
      try {
        self.checkingIsAuth = true
        const response = yield AuthService.login({ phone, password })
        if (response.status === 401) {
          self.accessToken = ""
          self.refreshToken = ""
        }
        self.accessToken = response.data?.data?.accessToken
        self.refreshToken = response.data?.data?.refreshToken
        self.user = response.data?.data?.user
        self.role = response.data?.data?.role
        self.checkingIsAuth = false
        return Promise.resolve(response)
      } catch (error) {
        self.checkingIsAuth = false
        self.user = null
        return Promise.reject(error)
      }
    }),
    setCredentials({ accessToken, refreshToken, user, role }) {
      self.accessToken = accessToken
      self.refreshToken = refreshToken
      self.user = user
      self.role = role
    },
    loadUserInfo: flow(function* () {
      const response = yield AuthService.getUserInfo()
      localStorage.setItem("user", JSON.stringify(response?.data?.data?.user))
      const user = response.data?.data?.user
      self.user = { ...user, salonId: user.salon_id ?? null }
    }),
    clear() {
      self.accessToken = ""
      self.user = null
      self.role = undefined
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.accessToken = localStorage.getItem("accessToken") ?? ""
      self.refreshToken = localStorage.getItem("refreshToken") ?? ""

      if (self.accessToken.length > 0) {
        self.loadUserInfo()
      }

      const accessTokenDisposer = reaction(
        () => self.accessToken,
        (token) => {
          if (!token?.length) {
            localStorage.removeItem("accessToken")
            return
          }

          localStorage.setItem("accessToken", `Bearer ${token}`)
          self.loadUserInfo()
        }
      )
      const refreshTokenDisposer = reaction(
        () => self.refreshToken,
        (token) => {
          if (!token?.length) {
            localStorage.removeItem("refreshToken")
            return
          }
          localStorage.setItem("refreshToken", `Bearer ${token}`)
        }
      )

      addDisposer(self, accessTokenDisposer)
      addDisposer(self, refreshTokenDisposer)
    },
  }))
