import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { TableSpinner } from "shared/ui/dataTable/spinner"

const user = JSON.parse(localStorage.getItem("user"))
const isMobile = window.screen.width < 500
const userRoleId = user?.roles?.[0]?.id
const category_id = JSON.parse(localStorage.getItem("category_id"))
  ? JSON.parse(localStorage.getItem("category_id"))
  : user?.restaurant?.category_id

export function MobileSidebar({ pathList, toggleNavbar, isHidden }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { step } = useSelector((state) => state.StepSliceReducer)

  function navigateTo(path) {
    navigate(path)
    setTimeout(() => {
      toggleNavbar()
    }, 300)
  }
  //тут добавил step
  //новый функционал
  const [showMenu, setShowMenu] = useState(false)
  const user = JSON.parse(localStorage.getItem("user"))
  const isAdmin = user?.admin
  // const category_id = user?.salon?.category_id

  const isLabAccessible = step === "5"

  const openMenu = (e) => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  if (!userRoleId) {
    return <TableSpinner />
  }

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${
          isHidden ? "translate-x-[-110%]" : "translate-x-0"
        } transition-transform duration-300 sm:hidden flex fixed top-0 left-0 bottom-0 z-[100]`}
      >
        <div className=' h-full bg-blue px-6 [&>a]:mb-5 w-fit z-10'>
          <div className='flex items-center justify-between'>
            <div className='w-fit h-fit flex text-orange sm:text-2xl text-xl my-7'>
              <img src='./assets/logo/logo.svg' alt='Logo' />
            </div>
            <button
              onClick={toggleNavbar}
              className='text-white hover:text-orange h-fit [&>svg]:w-[20px]'
            >
              <KeyboardDoubleArrowLeftOutlined className='w-[20px] text-orange' />
            </button>
          </div>

          {/* {isLabAccessible ? (
              <>
              <div
              onClick={(e) => openMenu(e)}
              className={`text-white
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                  cursor-pointer
                `}
            >
              <div className="group-hover:text-orange">
                {
                  <AssignmentOutlinedIcon
                    sx={{
                      width: "20px",
                      marginRight: isHidden ? "0px" : "15px",
                      color: `${pathname === "/laboratory" && "secondary.main"}`,
                    }}
                  />
                }
              </div>
              {!isHidden && (
                <>
                  <p
                    className={`transitional transitionAppearance w-fit pr-[10px]`}
                  >
                    Лаборатория
                  </p>
                  <div className='text-white hover:text-orange'>
                    {showMenu ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon />}
                  </div>
                </>

              )}
            </div>
            {showMenu &&
              <div
              onClick={e => e.stopPropagation()}
            className="ml-6 flex flex-col justify-center items-center">
            <Link
              to="/templates"
            onClick={toggleNavbar}
              className={`${
                pathname === "/templates"
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                `}
            >
              Шаблоны
            </Link>
            <Link
              to='/analyzes'
              onClick={toggleNavbar}
              className={`${
                pathname.includes('/analyzes')
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
              flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
              sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
            `}
            >
              Анализы
            </Link>
        </div>
        }
            </>
          ) : (    
           <div className="text-gray flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal cursor-not-allowed">
                <AssignmentOutlinedIcon sx={{ width: "20px", marginRight: isHidden ? "0px" : "15px" }} />
                {!isHidden && <p className="transitionAppearance w-fit pr-[10px]">Лаборатория</p>}
              </div>
          
          )}
          {pathList.map(({ path, value, Icon }) => (
            <button
              key={path}
              onClick={() => navigateTo(path)}
            className={`${ pathname === path ? "text-orange [&>div]:text-orange" :       
                  step === "0" && value === "Настройки" ? "hover:text-orange [&>div]:text-gray group text-white" : step === "1" && (value === "Категории услуг" || value === "Настройки") ? "hover:text-orange [&>div]:text-gray group text-white" : step === "2" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки") ?
                  "hover:text-orange [&>div]:text-gray group text-white" : step === "3" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки" || value === 'Сотрудники') ?  "hover:text-orange [&>div]:text-gray group text-white" : step === "4" || step === '5' ? "hover:text-orange [&>div]:text-gray group text-white" : "text-[#9a9c9a] pointer-events-none" }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
                `}
            >
              <div>
                {
                  <Icon
                    sx={{
                      width: "20px",
                      marginRight: "15px",
                      color: `${pathname === path && "secondary.main"}`,
                    }}
                  />
                }
              </div>
              <span className='w-fit'>{value}</span>
            </button>
          ))} */}

          {/* {pathList.map(({ path, value, Icon }, index) => {
            // Для category_id === 1 показываем только "Записи", "Обратная связь" и "Настройки" без шагов
            if (category_id === 6) {
              const isVisibleForCategory1 = [
                "Записи",
                "Обратная связь",
                "Настройки",
              ].includes(value)

              return (
                isVisibleForCategory1 && (
                  <div key={path}>
                    <button
                      onClick={() => navigateTo(path)}
                      className={`${
                        pathname === path
                          ? "text-orange [&>div]:text-orange"
                          : "hover:text-orange [&>div]:text-gray group text-white"
                      }
            flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal`}
                    >
                      <div>
                        {
                          <Icon
                            sx={{
                              width: "20px",
                              marginRight: "15px",
                              color: `${pathname === path && "secondary.main"}`,
                            }}
                          />
                        }
                      </div>
                      <span className='w-fit'>{value}</span>
                    </button>
                  </div>
                )
              )
            }

            // Логика для остальных категорий с шагами (step)
            const hideStepItems =
              userRoleId !== 4 &&
              [
                "Категории услуг",
                "Услуги",
                "Настройки",
                "Сотрудники",
                "Клиенты",
                "Записи",
              ].includes(value)

            return (
              <div key={path}>
                {!hideStepItems && (
                  <button
                    onClick={() => navigateTo(path)}
                    className={`${
                      pathname === path
                        ? "text-orange [&>div]:text-orange"
                        : step === "0" && value === "Настройки"
                          ? "hover:text-orange [&>div]:text-gray group text-white"
                          : step === "1" &&
                              (value === "Категории услуг" ||
                                value === "Настройки")
                            ? "hover:text-orange [&>div]:text-gray group text-white"
                            : step === "2" &&
                                (value === "Категории услуг" ||
                                  value === "Услуги" ||
                                  value === "Настройки")
                              ? "hover:text-orange [&>div]:text-gray group text-white"
                              : step === "3" &&
                                  (value === "Категории услуг" ||
                                    value === "Услуги" ||
                                    value === "Настройки" ||
                                    value === "Сотрудники")
                                ? "hover:text-orange [&>div]:text-gray group text-white"
                                : step === "4" || step === "5"
                                  ? "hover:text-orange [&>div]:text-gray group text-white"
                                  : "text-[#9a9c9a] pointer-events-none"
                    }
          flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal`}
                  >
                    <div>
                      {
                        <Icon
                          sx={{
                            width: "20px",
                            marginRight: "15px",
                            color: `${pathname === path && "secondary.main"}`,
                          }}
                        />
                      }
                    </div>
                    <span className='w-fit'>{value}</span>
                  </button>
                )} */}

          {/* временно коментируем ниже код */}
          {pathList.map(({ path, value, Icon }, index) => {
            const hideStepItems =
              userRoleId !== 4 &&
              [
                "Категории услуг",
                "Услуги",
                "Настройки",
                "Сотрудники",
                "Клиенты",
                "Записи",
              ].includes(value)
            return (
              <div key={path}>
                {!hideStepItems && (
                  <button
                    onClick={() => navigateTo(path)}
                    className={`${
                      pathname === path
                        ? "text-orange [&>div]:text-orange"
                        : step === "0" && value === "Настройки"
                          ? "hover:text-orange [&>div]:text-gray group text-white"
                          : step === "1" &&
                              (value === "Категории услуг" ||
                                value === "Настройки")
                            ? "hover:text-orange [&>div]:text-gray group text-white"
                            : step === "2" &&
                                (value === "Категории услуг" ||
                                  value === "Услуги" ||
                                  value === "Настройки")
                              ? "hover:text-orange [&>div]:text-gray group text-white"
                              : step === "3" &&
                                  (value === "Категории услуг" ||
                                    value === "Услуги" ||
                                    value === "Настройки" ||
                                    value === "Сотрудники")
                                ? "hover:text-orange [&>div]:text-gray group text-white"
                                : step === "4" || step === "5"
                                  ? "hover:text-orange [&>div]:text-gray group text-white"
                                  : "text-[#9a9c9a] pointer-events-none"
                    }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
                `}
                  >
                    <div>
                      {
                        <Icon
                          sx={{
                            width: "20px",
                            marginRight: "15px",
                            color: `${pathname === path && "secondary.main"}`,
                          }}
                        />
                      }
                    </div>
                    <span className='w-fit'>{value}</span>
                  </button>
                )}

                {/* Вставляем компонент "Лаборатория" под "Услугами" */}

                {/* Временно скрываем лабоаторию */}

                {/* {value === "Услуги" && isLabAccessible && (
                <>
                  <div
                    onClick={(e) => openMenu(e)}
                    className={`text-white
                      flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                      sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                      cursor-pointer
                    `}
                  >
                    <div className="group-hover:text-orange">
                      {
                        <AssignmentOutlinedIcon
                          sx={{
                            width: "20px",
                            marginRight: isHidden ? "0px" : "15px",
                            color: `${pathname === "/laboratory" && "secondary.main"}`,
                          }}
                        />
                      }
                    </div>
                    {!isHidden && (
                      <>
                        <p
                          className={`transitional transitionAppearance w-fit pr-[10px]`}
                        >
                          Лаборатория
                        </p>
                        <div className='text-white hover:text-orange'>
                          {showMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </div>
                      </>
                    )}
                  </div>
                  {showMenu &&
                    <div
                      onClick={e => e.stopPropagation()}
                      className="ml-6 flex flex-col justify-center items-center">
                      <Link
                        to="/templates"
                        onClick={toggleNavbar}
                        className={`${
                          pathname === "/templates"
                            ? "text-orange [&>div]:text-orange"
                            : "text-white"
                        }
                        flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                        sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                      `}
                      >
                        Шаблоны
                      </Link>
                      <Link
                        to='/analyzes'
                        onClick={toggleNavbar}
                        className={`${
                          pathname.includes('/analyzes')
                            ? "text-orange [&>div]:text-orange"
                            : "text-white"
                        }
                        flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                        sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                      `}
                      >
                        Анализы
                      </Link>
                    </div>
                  }
                </>
              )} */}
              </div>
            )
          })}
        </div>
      </div>
      {isMobile && (
        <div
          onTouchStartCapture={(e) => setTimeout(toggleNavbar, 100)}
          className={`sidebar absolute left-0 top-0 right-0 bottom-0 bg-blue/30 z-20 ${
            isHidden ? "invisible opacity-0" : "visible opacity-100"
          } transition-opacity`}
        ></div>
      )}
    </>
  )
}