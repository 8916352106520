import { useRef, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import "./style.css"

import {
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
} from "@mui/icons-material"
import { useSelector } from "react-redux"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { TableSpinner } from "shared/ui/dataTable/spinner"

const user = JSON.parse(localStorage.getItem("user"))
const userRoles = user?.roles || []
const isAdmin = user?.admin
const userRoleId = user?.roles?.[0]?.id

const retaurant_id = JSON.parse(localStorage.getItem("category_id"))
  ? JSON.parse(localStorage.getItem("retaurant_id"))
  : user?.restaurant?.category_id
export const Navbar = ({ pathList, hidden, setHidden }) => {
  const { step } = useSelector((state) => state.StepSliceReducer)
  const wrapRef = useRef(null)
  const { pathname } = useLocation()
  const { settings } = useSelector((state) => state.profileReducer)

  const [showMenu, setShowMenu] = useState(false)
  console.log(user, "category_id")

  // console.log(user.roles[0].id, "user")

  const isLabAccessible = step === "5"
  function entered() {
    setHidden(false)
    wrapRef.current.classList.remove("leaveWidth")
    wrapRef.current.classList.add("enterWidth")
  }

  function leaved() {
    setHidden(true)
    wrapRef.current.classList.remove("enterWidth")
    wrapRef.current.classList.add("leaveWidth")
  }

  const openMenu = (e) => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  if (!userRoleId) {
    return <TableSpinner />
  }

  return (
    <>
      <div
        onClick={() => setShowMenu(false)}
        // onMouseEnter={entered}
        // onMouseLeave={leaved}
        className='hidden sm:flex bg-blue px-6'
      >
        <div
          ref={wrapRef}
          className='sticky left-0 top-0 bottom-0 h-screen navContent overflow-y-auto'
        >
          <div className='mt-10 sm:mt-14 mb-16 flex justify-between items-center'>
            <div className='w-fit h-fit flex text-orange sm:text-2xl text-xl'>
              {!hidden && (
                <img
                  src='/assets/logo/logo.svg'
                  alt='Logo'
                  className='transitionWidth h-5'
                />
              )}
            </div>
            <button
              onClick={hidden ? entered : leaved}
              className='[&>svg]:text-lightGray [&>svg]:hover:text-orange h-fit [&>svg]:w-[20px]'
            >
              {hidden ? (
                <KeyboardDoubleArrowRightOutlined
                  className='w-[20px]'
                  fontSize='small'
                />
              ) : (
                <KeyboardDoubleArrowLeftOutlined className='w-[20px]' />
              )}
            </button>
          </div>
          <nav className='flex flex-col'>
            {/* {isLabAccessible ? (
              <>
              <div
              onClick={(e) => openMenu(e)}
              className={`text-white
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                  cursor-pointer
                `}
            >
              <div className="group-hover:text-orange">
                {
                  <AssignmentOutlinedIcon
                    sx={{
                      width: "20px",
                      marginRight: hidden ? "0px" : "15px",
                      color: `${pathname === "/laboratory" && "secondary.main"}`,
                    }}
                  />
                }
              </div>
              {!hidden && (
                <>
                  <p
                    className={`transitional transitionAppearance w-fit pr-[10px]`}
                  >
                    Лаборатория
                  </p>
                  <div className='text-white hover:text-orange'>
                    {showMenu ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon />}
                  </div>
                </>

              )}
            </div>
            {showMenu &&
              <div
              onClick={e => e.stopPropagation()}
            className="ml-6 flex flex-col justify-center items-center">
            <Link
              to="/templates"
              className={`${
                pathname === "/templates"
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                `}
            >
              Шаблоны
            </Link>
            <Link
              to='/analyzes'
              className={`${
                pathname.includes('/analyzes')
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
              flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
              sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
            `}
            >
              Анализы
            </Link>

        </div>
            }
            </>
         ) : (
          <div className="text-gray flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal cursor-not-allowed">
                <AssignmentOutlinedIcon sx={{ width: "20px", marginRight: hidden ? "0px" : "15px" }} />
                {!hidden && <p className="transitionAppearance w-fit pr-[10px]">Лаборатория</p>}
              </div>
          )} */}

            {/* {pathList.map(({ path, value, Icon }) => (
              (isAdmin === '0' && value === 'Настройки') ?
                null :
              <NavLink
                key={path}
                to={path}
                className={`${ pathname === path ? "text-orange [&>div]:text-orange" :       
                  step === "0" && value === "Настройки" ? "hover:text-orange [&>div]:text-gray group text-white" : step === "1" && (value === "Категории услуг" || value === "Настройки") ? "hover:text-orange [&>div]:text-gray group text-white" : step === "2" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки") ?
                  "hover:text-orange [&>div]:text-gray group text-white" : step === "3" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки" || value === 'Сотрудники') ?  "hover:text-orange [&>div]:text-gray group text-white" : step === "4" || step === '5' ? "hover:text-orange [&>div]:text-gray group text-white" : "text-[#9a9c9a] pointer-events-none" }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
                `}
              >
                <div className="group-hover:text-orange">
                  {
                    <Icon
                      sx={{
                        width: "20px",
                        marginRight: hidden ? "0px" : "15px",
                        color: `${pathname === path && "secondary.main"}`,
                      }}
                    />
                  }
                </div>
                {!hidden && (
                  <span
                    className={`transitional transitionAppearance w-fit pr-[10px]`}
                  >
                    {value}
                  </span>
                )}
              </NavLink>
            ))} */}

            {/* {pathList.map(({ path, value, Icon }, index) => {
              if (retaurant_id === 6) {
                const isVisibleForCategory1 = [
                  "Записи",
                  "Обратная связь",
                  "Настройки",
                ].includes(value)

                return (
                  isVisibleForCategory1 && (
                    <div key={path}>
                      <NavLink
                        to={path}
                        className={`${
                          pathname === path
                            ? "text-orange [&>div]:text-orange"
                            : "hover:text-orange [&>div]:text-gray group text-white"
                        } flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal`}
                      >
                        <div className='group-hover:text-orange'>
                          {
                            <Icon
                              sx={{
                                width: "20px",
                                marginRight: hidden ? "0px" : "15px",
                                color: `${pathname === path && "secondary.main"}`,
                              }}
                            />
                          }
                        </div>
                        {!hidden && (
                          <span className='transitional transitionAppearance w-fit pr-[10px]'>
                            {value}
                          </span>
                        )}
                      </NavLink>
                    </div>
                  )
                )
              }

              // Логика для остальных категорий с шагами (step)
              const hideStepItems =
                userRoleId !== 4 &&
                [
                  "Категории услуг",
                  "Услуги",
                  "Настройки",
                  "Сотрудники",
                  "Клиенты",
                  "Записи",
                ].includes(value)

              const stepClass =
                pathname === path
                  ? "text-orange [&>div]:text-orange"
                  : step === "0" && value === "Настройки"
                    ? "hover:text-orange [&>div]:text-gray group text-white"
                    : step === "1" &&
                        (value === "Категории услуг" || value === "Настройки")
                      ? "hover:text-orange [&>div]:text-gray group text-white"
                      : step === "2" &&
                          (value === "Категории услуг" ||
                            value === "Услуги" ||
                            value === "Настройки")
                        ? "hover:text-orange [&>div]:text-gray group text-white"
                        : step === "3" &&
                            (value === "Категории услуг" ||
                              value === "Услуги" ||
                              value === "Настройки" ||
                              value === "Сотрудники")
                          ? "hover:text-orange [&>div]:text-gray group text-white"
                          : step === "4" || step === "5"
                            ? "hover:text-orange [&>div]:text-gray group text-white"
                            : "text-[#9a9c9a] pointer-events-none" // Неактивные разделы

              return (
                <div key={path}>
                  {!hideStepItems && (
                    <NavLink
                      to={path}
                      className={`
            ${stepClass}
            flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
          `}
                    >
                      <div className='group-hover:text-orange'>
                        {
                          <Icon
                            sx={{
                              width: "20px",
                              marginRight: hidden ? "0px" : "15px",
                              color: `${pathname === path && "secondary.main"}`,
                            }}
                          />
                        }
                      </div>
                      {!hidden && (
                        <span className='transitional transitionAppearance w-fit pr-[10px]'>
                          {value}
                        </span>
                      )}
                    </NavLink>
                  )} */}

            {/* временно коментируем ниже */}
            {pathList.map(({ path, value, Icon }, index) => {
              const hideStepItems =
                userRoleId !== 4 &&
                [
                  "Категории услуг",
                  "Услуги",
                  "Настройки",
                  "Сотрудники",
                  "Клиенты",
                  "Записи",
                ].includes(value)

              return (
                <div key={path}>
                  {!hideStepItems && (
                    <NavLink
                      to={path}
                      className={`${
                        pathname === path
                          ? "text-orange [&>div]:text-orange"
                          : step === "0" && value === "Настройки"
                            ? "hover:text-orange [&>div]:text-gray group text-white"
                            : step === "1" &&
                                (value === "Категории услуг" ||
                                  value === "Настройки")
                              ? "hover:text-orange [&>div]:text-gray group text-white"
                              : step === "2" &&
                                  (value === "Категории услуг" ||
                                    value === "Услуги" ||
                                    value === "Настройки")
                                ? "hover:text-orange [&>div]:text-gray group text-white"
                                : step === "3" &&
                                    (value === "Категории услуг" ||
                                      value === "Услуги" ||
                                      value === "Настройки" ||
                                      value === "Сотрудники")
                                  ? "hover:text-orange [&>div]:text-gray group text-white"
                                  : step === "4" || step === "5"
                                    ? "hover:text-orange [&>div]:text-gray group text-white"
                                    : "text-[#9a9c9a] pointer-events-none"
                      }
                      flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
                    `}
                    >
                      <div className='group-hover:text-orange'>
                        {
                          <Icon
                            sx={{
                              width: "20px",
                              marginRight: hidden ? "0px" : "15px",
                              color: `${pathname === path && "secondary.main"}`,
                            }}
                          />
                        }
                      </div>
                      {!hidden && (
                        <span
                          className={`transitional transitionAppearance w-fit pr-[10px]`}
                        >
                          {value}
                        </span>
                      )}
                    </NavLink>
                  )}

                  {/* Вставляем компонент "Лаборатория" под "Услугами" */}

                  {/* Временно скрываем лабораторию */}

                  {/* {value === "Услуги" && isLabAccessible && (
                    <>
                      <div
                        onClick={(e) => openMenu(e)}
                        className={`text-white
                          flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                          sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                          cursor-pointer
                        `}
                      >
                        <div className="group-hover:text-orange">
                          {
                            <AssignmentOutlinedIcon
                              sx={{
                                width: "20px",
                                marginRight: hidden ? "0px" : "15px",
                                color: `${pathname === "/laboratory" && "secondary.main"}`,
                              }}
                            />
                          }
                        </div>
                        {!hidden && (
                          <>
                            <p
                              className={`transitional transitionAppearance w-fit pr-[10px]`}
                            >
                              Лаборатория
                            </p>
                            <div className='text-white hover:text-orange'>
                              {showMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </div>
                          </>
                        )}
                      </div>
                      {showMenu &&
                        <div
                          onClick={e => e.stopPropagation()}
                          className="ml-6 flex flex-col justify-center items-center">
                          <Link
                            to="/templates"
                            className={`${
                              pathname === "/templates"
                                ? "text-orange [&>div]:text-orange"
                                : "text-white"
                            }
                            flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                            sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                          `}
                          >
                            Шаблоны
                          </Link>
                          <Link
                            to='/analyzes'
                            className={`${
                              pathname.includes('/analyzes')
                                ? "text-orange [&>div]:text-orange"
                                : "text-white"
                            }
                            flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                            sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                          `}
                          >
                            Анализы
                          </Link>
                        </div>
                      }
                    </>
                  )} */}
                </div>
              )
            })}
          </nav>
        </div>
      </div>
    </>
  )
}